/*
 * @Author: Chu Yifei
 * @LastEditors: Chu Yifei
 */
import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { CreateElement } from 'vue'
import $ from 'jquery'
// @ts-ignore
import Simditor from 'simditor'
import 'simditor/styles/simditor.css'
import options from './config'
import './style.less'
let uid = 1

@Component
export class SimditorVue extends Vue {
  @Model('change')
  public value!: any
  @Prop({ default: () => {} })
  public globalOptions!: any
  @Prop({ default: false })
  public disabled!: boolean

  public editor: any
  public random = uid++

  public mounted() {
    this.initialize()
  }

  public initialize() {
    const option = Object.assign(
      {},
      { textarea: $(`#textarea${this.random}`) },
      options,
      this.globalOptions
    )
    this.editor = new Simditor(option)
    this.changeValue(this.value)
    this.editor.on('valuechanged', (e: any, src: any) => {
      this.$emit('change', this.editor.getValue())
    })
    this.editor.uploader.on('uploadsuccess', (e: any, file: any, res: any) => {
      const img = file.img
      if (file.xhr.status === 200) {
        img.attr('src', process.env.VUE_APP_FILE_URL + res.key)
      } else {
        this.$message.error('图片上传失败,请重新上传')
      }
    })
  }

  public changeValue(value: string) {
    if (value) {
      this.editor.setValue(value)
    }
  }

  public render(h: CreateElement) {
    return h('div', [
      h('textarea', {
        attrs: {
          id: `textarea${this.random}`,
        },
      }),
    ])
  }
}
