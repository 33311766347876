import { CommonController } from '@/services/request.service'
const getToken = () => CommonController.getQiniuEtcToken()

const params = {
  token: '',
}

async function tokenUpdate() {
  const res = await getToken()
  params.token = res.data.token
}

tokenUpdate()

setInterval(tokenUpdate, 60 * 60 * 1000)

const config = {
  placeHolder: '',
  disabled: true,
  toolbarFloat: false,
  toolbar: [
    'title',
    'bold',
    'italic',
    'underline',
    // 'strikethrough',
    'fontScale',
    'color',
    'ol',
    'ul',
    'blockquote',
    'table',
    'link',
    'image',
    // 'hr',
    // 'indent',
    // 'outdent',
    'alignment',
  ],
  pasteImage: true,
  allowedTags: [
    'br',
    'span',
    'a',
    'img',
    'b',
    'strong',
    'i',
    'strike',
    'u',
    'font',
    'p',
    'ul',
    'ol',
    'li',
    'blockquote',
    'pre',
    'code',
    'h1',
    'h2',
    'h3',
    'h4',
    'hr',
    'table',
    'thead',
    'tbody',
    'colgroup',
    'col',
    'tr',
    'td',
  ],
  upload: {
    url: 'https://upload.qiniup.com/', // 文件上传的接口地址
    params: params, // 键值对,指定文件上传接口的额外参数,上传的时候随文件一起提交
    fileKey: 'file', // 服务器端获取文件数据的参数名
    connectionCount: 3,
    leaveConfirm: '正在上传文件',
  },
}

export default config
